<template>
  <v-btn
    class="br-btn"
    color="secondary"
    @click="$emit('onClick')"
    :loading="loading">
    Confirmar
  </v-btn>
</template>

<script>
export default {
  name: 'OrganizzeConfirmationButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  directives: {},
  components: {},
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>